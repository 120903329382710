import Actions from "modules/actions/accession-actions";

export default {
  name: "AddPayorModal",
  props: {
    addPayor: {
      type: Boolean,
      required: true,
    },
    unkCount: { type: Number, value: 0 },
  },
  data() {
    return {
      actions: new Actions(),
      isSearch: false,
      isLazyLoading: false,
      loadInitialData: false,
      payorIdModalLoader: true,
      searchField: "",
      totalCount: 0,
      errorOccur: false,
      selectedPayors: [],
      payorIdModalOptions: [],
      agination: {},
      payorIdModalSearchOptions: [],
      limitData: ["insuranceCompanyId", "name", "payorGroup", "payorSubGroup"],
      pagination: {
        page: 5,
        itemsPerPage: 10,
        pageStop: 50,
        pageCount: 32,
      },
      payorHeaders: [
        {
          text: "Payor ID",
          align: "start",
          sortable: false,
          value: "payorId",
        },
        { text: "Payor Name", sortable: false, value: "name" },
      ],
    };
  },
  computed: {
    disableUnkPayor() {
      if (this.unkCount == 5) return true;
      else
        return (
          this.unkCount +
            this.selectedPayors.filter(
              (payor) => payor.payorGroup.toUpperCase() == "UNK"
            ).length ==
          5
        );
    },
    itemList() {
      return this.payorIdModalLoader
        ? []
        : this.isSearch
        ? this.payorIdModalSearchOptions
        : this.payorIdModalOptions;
    },
    isAllPayorsLoaded() {
      return this.payorIdModalOptions.length == this.totalCount;
    },
    openModal: {
      get() {
        return this.addPayor;
      },
      set(value) {
        this.$emit("update:addPayor", value);
      },
    },
  },
  watch: {
    async searchField () {
      await this.searchPayorsDebounce();
    },
    async isLazyLoading () {
      if (this.isAllPayorsLoaded) return;

      await this.getPayorsDebounce();
    },
    async addPayor () {
      if (!this.loadInitialData) await this.getPayorsDebounce();
    },
  },
  methods: {
    async addSelected() {
      this.$emit("addSelected", this.selectedPayors);
      this.selectedPayors = [];
    },
    parseInsuranceCompanyResponseData(companies) {
      return companies.map((company) => {
        return {
          id: company.id,
          payorId: company.insuranceCompanyId,
          name: company.name,
          payorGroup: company.payorGroup,
          payorSubGroup: company.payorSubGroup,
        };
      });
    },
    async getPayors() {
      // api call here
      // and haddle of response
      setTimeout(async () => {
        try {
          if (this.openModal && !this.loadInitialData) {
            this.payorIdModalLoader = true;
            const insuranceCompanies =
              await this.actions.getInsuranceCompanyList({
                limit: 20,
                limitData: [...this.limitData, "totalCount"],
                includeInactive: false,
              });
            this.loadInitialData = true;
            this.payorIdModalOptions =
              this.parseInsuranceCompanyResponseData(insuranceCompanies);
            this.totalCount = insuranceCompanies[0].totalCount;
          } else {
            {
              if (this.isLazyLoading) {
                const insuranceCompanies =
                  await this.actions.getInsuranceCompanyList({
                    limit: 10,
                    limitData: this.limitData,
                    offset: this.payorIdModalOptions.length,
                    includeInactive: false,
                  });
                this.payorIdModalOptions = this.payorIdModalOptions.concat(
                  this.parseInsuranceCompanyResponseData(insuranceCompanies)
                );
              }
              this.isLazyLoading = false;
            }
          }
          this.payorIdModalLoader = false;
        } catch (e) {
          this.errorOccur = true;
        }
      }, 500);
    },
    async searchPayors() {
      setTimeout(async () => {
        try {
          this.payorIdModalLoader = true;
          if (this.searchField && this.searchField.length > 2) {
            this.isSearch = true;
            const insuranceCompanies =
              await this.actions.getInsuranceCompanyList({
                filter: {
                  name: this.searchField,
                  insuranceCompanyId: this.searchField,
                },
                limitData: this.limitData,
                includeInactive: false,
                }, "to seacrh payors");
            this.payorIdModalSearchOptions = this.parseInsuranceCompanyResponseData(insuranceCompanies);
          } else {
            this.payorIdModalSearchOptions = [];
            this.isSearch = false;
          }
          this.payorIdModalLoader = false;
        } catch (e) {
          this.errorOccur = true;
        }
      }, 500);
    },
    async refetchPayors() {
      await this.getPayorsDebounce();
      this.errorOccur = false;
    },
  },
  async created() {
    this.getPayorsDebounce = await this.debounce(
      async () => await this.getPayors()
    );
    this.searchPayorsDebounce = await this.debounce(
      async () => await this.searchPayors()
    );
  },
};
