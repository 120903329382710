export default {
  name: "AddInternalNoteModal",
  data() {
    return {
      isSaving: false,
      note: "",
      rules: {
        required: (val) =>
          (val !== null && val.length !== 0) || "This field is required.",
      },
    };
  },
  methods: {
    async addNote() {
      try {
        this.isSaving = true;
        const validate = await this.$refs.addNoteForm.validateAsync();

        if (!validate) return;
        this.$emit("addNote", this.note);
      } catch (err) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      } finally {
        this.isSaving = false;
      }
    },
  },
};
