import PlaceOfService from "store/models/PlaceOfService";

import StoreActions from "store/actions";
const storeActions = new StoreActions(PlaceOfService);

export default class PlaceOfServiceActions {
  create(variables, options, description) {
    return storeActions.mutation(variables, options, description);
  }

  getByPosCode(posCode) {
    return storeActions.query(
      {
        relativeUrl: "/place-of-service/get-all-pos",
        filter: {
          posCode,
        },
        queryName: "placeOfServices",
      },
      "to fetch place of service by posCode"
    );
  }

  getPosList(variables) {
    return storeActions.query(
      {
        relativeUrl: "/place-of-service/get-all-pos",
        queryName: "placeOfServices",
        ...variables,
      },
      "to fetch place of service by posCode"
    );
  }
}
