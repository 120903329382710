import OrderBillingDiagnosticCode from "store/models/OrderBillingDiagnosticCode";

import StoreActions from "store/actions";

const storeActions = new StoreActions(OrderBillingDiagnosticCode);

export default class OrderBillingDiagnosticCodeActions {
  async update(variables) {
    return await storeActions.mutation(
      {
        queryName: "updateOrderBillingDiagnosticCodeDetails",
        relativeUrl: "/obdc/update-obdc-details",
        queries: variables.queries,
      },
      "To update account.",
      {
        orderBillingDiagnosticCodeId: variables.orderBillingDiagnosticCodeId,
      }
    );
  }
}
