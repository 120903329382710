import BillingClaimsSubmission from "store/models/BillingClaimsSubmission";

import StoreActions from "store/actions";

const storeActions = new StoreActions(BillingClaimsSubmission);

export default class BillingClaimsSubmissionActions {

  async create(variables) {
    return await storeActions.mutation(
      {
        queryName: "createBillingClaimsSubmission",
        relativeUrl: "/claims-submission/create-claims-submission",
        queries: variables.queries || [],
        collectionQueries: variables.collectionQueries || [],
      },
      "To create claims submission.",
      {...variables.payload}
    );
  }


  async update(variables) {
    return await storeActions.mutation(
      {
        queryName: "updateBillingClaimsSubmissionDetails",
        relativeUrl: "/claims-submission/update-claims-submission",
        queries: variables.queries,
        collectionQueries: variables.collectionQueries || [],
      },
      "To update claims submission.",
      {
        billingClaimsSubmissionId: variables.billingClaimsSubmissionId
      }
    );
  }

}
