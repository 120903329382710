import BillingTransactionDetails from "store/models/BillingTransactionDetails";

import StoreActions from "store/actions";

const storeActions = new StoreActions(BillingTransactionDetails);

export default class BillingTransactionDetailsActions {

  async create(variables) {
    return await storeActions.mutation(
      {
        queryName: "createBillingTransactionDetails",
        relativeUrl: "/transaction-details/create-transaction-details",
        queries: variables.queries || [],
        collectionQueries: variables.collectionQueries || [],
      },
      "To create transaction details.",
      {...variables.payload}
    );
  }


  async update(variables) {
    return await storeActions.mutation(
      {
        queryName: "updateBillingTransactionDetails",
        relativeUrl: "/transaction-details/update-transaction-details",
        queries: variables.queries,
        collectionQueries: variables.collectionQueries || [],
      },
      "To update transaction details.",
      {
        billingTransactionDetailsId: variables.billingTransactionDetailsId
      }
    );
  }

}
