import StoreActions from "store/actions";
import ConversionTransaction from "store/models/ConversionTransaction";

const conversionTrxActions = new StoreActions(ConversionTransaction);

const updateResult = ["success", "entityId", "errors", "code"];

export default class conversionTransactionActions {
  // and also update
  async create(params) {
    return await conversionTrxActions.mutation(
      {
        queryName: "createVbsConversionTransaction",
        relativeUrl: "/conversion-transaction/create-conversion-transaction",
        limitData: updateResult,
        shouldNotReturnId: true,
      },
      "To create new transaction.",
      {
        ...params,
      }
    );
  }

  async update(params) {
    return await conversionTrxActions.mutation(
      {
        queryName: "updateVbsConversionTransaction",
        relativeUrl: "/conversion-transaction/update-conversion-transaction",
        limitData: updateResult,
        shouldNotReturnId: true,
      },
      "To update transaction.",
      {
        ...params,
      }
    );
  }

  async commit(params) {
    return await conversionTrxActions.mutation(
      {
        relativeUrl: "/conversion-transaction/commit-conversion-transactions",
        limitData: updateResult,
        queryName: "commitVbsConversionTransactions",
        shouldNotReturnId: true,
      },
      "To validate all test ordered transaction",
      {
        ...params
      }
    );
  }

  async upsert(params) {
    return await conversionTrxActions.mutation(
      {
        relativeUrl: "/conversion-transaction/upsert-conversion-transaction",
        limitData: updateResult,
        queryName: "upsertVbsConversionTransactions",
        shouldNotReturnId: true,
      },
      "To update status of conversion rule.",
      {
        ...params
      }
    );
  }

  async revert(groupId) {
    return await conversionTrxActions.mutation(
      {
        relativeUrl: "/conversion-transaction/revert-conversion-transaction",
        limitData: updateResult,
        queryName: "revertVbsConversionTransaction",
        shouldNotReturnId: true,
      },
      "To update status of conversion rule.",
      {
        groupId: {
          type: "UUID!",
          value: groupId,
          unincludeToFields: true
        }
      }
    );
  }
}
