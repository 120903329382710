import EOBDepositInformation from "store/models/EOBDepositInformation";

import StoreActions from "store/actions";

const storeActions = new StoreActions(EOBDepositInformation);

export default class EobDepositInformationActions {
  async update(variables) {
    return await storeActions.mutation(
      {
        relativeUrl: "/eob-deposit-information/update-eob-deposit-information",
        queryName: "updateEobDepositInformationDetails",
        queries: variables.queries || {},
      },
      "To update an eob deposit information.",
      {
        eobDepositInformationId: variables.eobDepositInformationId,
      }
    );
  }

  async create(variables) {
    return await storeActions.mutation(
      {
        queryName: "createEobDepositInformation",
        relativeUrl: "/eob-deposit-information/create-eob-deposit-information",
        queries: variables.queries || [],
        collectionQueries: variables.collectionQueries || [],
      },
      "To create an eob deposit information.",
      { ...variables.payload }
    );
  }
}
