import Actions from "modules/actions";
import AccessionEntryActions from "store/actions/order";
import NpiActions from "store/actions/npi";

import PatientActions from "store/actions/patient";
import TestOrderedActions from "store/actions/test-ordered";
import AddressActions from "store/actions/address";
import PatientSignatureActions from "store/actions/patient-signature";
import InsuranceCompanyActions from "store/actions/insuranceCompany";
import InsuranceActions from "store/actions/insurance";
import InsuredActions from "store/actions/insured";
import MSPActions from "store/actions/msp";
import PhysicianActions from "store/actions/physician";
import BillingClaimsSubmission from "store/actions/billingClaimsSubmission";
import BillingTransactionDetails from "store/actions/billingTransactionDetails";
import TypeActions from "store/actions/types";
import BillingErrorCodeActions from "store/actions/billing-error-code";
import EOBDepositInformationActions from "store/actions/eobDepositInformation";
import PlaceOfServiceActions from "store/actions/place-of-service";
import OrderBillingDiagnosticCodeActions from "store/actions/billing-diagnostic-code";

const npiActions = new NpiActions();
const patientSignatureActions = new PatientSignatureActions();
const testOrderedActions = new TestOrderedActions();
const patientActions = new PatientActions();
const addressActions = new AddressActions();
const insuranceCompanyActions = new InsuranceCompanyActions();
const insuranceActions = new InsuranceActions();
const insuredActions = new InsuredActions();
const eobDepositInformationActions = new EOBDepositInformationActions();
const typeActions = new TypeActions();
const mspActions = new MSPActions();
const accessionAction = new AccessionEntryActions();
const physicianActions = new PhysicianActions();
const claimsSubmissionAction = new BillingClaimsSubmission();
const transactionDetailsAction = new BillingTransactionDetails();
const billingErrorCodeAction = new BillingErrorCodeActions();
const placeOfServiceActions = new PlaceOfServiceActions();
const billingDiagnosticCodeActions = new OrderBillingDiagnosticCodeActions();

export default class AccessionActions extends Actions {
  async updatePatientDetails(variables) {
    return await patientActions.update(variables);
  }

  async updatePatientSignatureDetails(variables) {
    return await patientSignatureActions.update(variables);
  }

  async getInsuranceCompanyList(variables) {
    return insuranceCompanyActions.getInsuranceCompanyList(variables);
  }

  async getMSPList(variables) {
    return mspActions.getMSPList(variables);
  }

  async updateAccessionDetails(variables) {
    const result = await accessionAction.update(variables);
    return result;
  }

  async updateInsurance(variables) {
    return insuranceActions.update(variables);
  }

  async createInsurance(variables) {
    return insuranceActions.create(variables);
  }

  async createInsured(variables) {
    return insuredActions.create(variables);
  }

  async getInsuranceRelationshipTypes(variables) {
    return typeActions.getInsuranceRelationshipTypes(variables);
  }

  async createAddress(variables) {
    return addressActions.create(variables);
  }

  async updatePhysicianDetails(variables) {
    return await physicianActions.update(variables);
  }

  async createBillingClaimsSubmission(variables) {
    return await claimsSubmissionAction.create(variables);
  }

  async updateBillingClaimsSubmission(variables) {
    return await claimsSubmissionAction.update(variables);
  }

  async createBillingTransactionDetails(variables) {
    return await transactionDetailsAction.create(variables);
  }

  async updateBillingTransactionDetails(variables) {
    return await transactionDetailsAction.update(variables);
  }
  async createBillingErrorCode(variables) {
    return await billingErrorCodeAction.create(variables);
  }

  async updateEobDepositInformation(variables) {
    return await eobDepositInformationActions.update(variables);
  }

  async createEobDepositInformation(variables) {
    return await eobDepositInformationActions.create(variables);
  }

  async sendChangeLogNotification(variables) {
    return await accessionAction.sendNotification(variables);
  }

  async updateTestOrderedDetails(variables) {
    return await testOrderedActions.update(variables);
  }

  async getPlaceOfServiceList(variables) {
    return await placeOfServiceActions.getPosList(variables);
  }

  async searchNpi(npiNumber) {
    return await npiActions.searchNpi(npiNumber);
  }

  async getInsuranceById(insuranceId, limitData = null) {
    return insuranceActions.get(insuranceId, limitData);
  }

  async updateOBDCDetails(variables) {
    return billingDiagnosticCodeActions.update(variables);
  }
}
