import { isEqual } from "lodash";
import CptCode from "store/models/CPTCode";
import Modifier from "store/models/Modifier";

export default {
  name: "NewTestOrdered",
  props: {
    show: Boolean,
    isEdit: Boolean,
    loading: Boolean,
    dateOfService: {
      type: String,
      required: true,
    },
    cptCode: {
      type: Object,
      default() {
        return null;
      },
    },
    modifier: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      dialog: false,
      cptCodeModel: CptCode,
      modifierModel: Modifier,
      selected: { cptCode: {}, modifier: {} },
      selectedCopy: { cptCode: {}, modifier: {} },
      rules: [
        () => !!this.dateOfService || "Date Of Service is not available.",
      ],
      additionalFilter: {},
      refresher: 0,
    };
  },
  computed: {
    hasChange() {
      return !isEqual(this.selected, this.selectedCopy);
    },
    initialValue() {
      const { cptCode, modifier } = this.selected;
      return {
        cptCode: this.selected.cptCode?.id
          ? {
              id: { label: "id", value: cptCode.id },
              label: cptCode.code,
              value: cptCode.code,
            }
          : null,
        modifier: this.selected.modifier?.id
          ? {
              id: { label: "id", value: modifier.id },
              label: modifier.modifierCode,
              value: modifier.modifierCode,
            }
          : null,
      };
    },
    excludedData() {
      return {
        cptCode: this.selected.cptCode?.id ? [this.selected.cptCode.id] : [],
        modifier: this.selected.modifier?.id ? [this.selected.modifier.id] : [],
      };
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.refresher += 1;
        this.selected = {
          cptCode: this.cptCode?.id ? this.cptCode : {},
          modifier: this.modifier?.id ? this.modifier : {},
        };
        this.selectedCopy = {
          cptCode: this.cptCode?.id ? this.cptCode : {},
          modifier: this.modifier?.id ? this.modifier : {},
        };
      } else this.refresher = 0;
    },
  },
  methods: {
    atCancel() {
      if (this.hasChange && !!this.dateOfService) this.dialog = true;
      else {
        this.clearSelected();
        this.$emit("closeModal");
      }
    },
    populateData(data, identifier) {
      if (identifier == "modifier") this.selected.modifier = data;
      else this.selected.cptCode = data;
      if (data.id) this.refresher += 1;
    },
    async addTestOrder() {
      const validate = await this.$refs.addTestOrderForm.validateAsync();
      if (!validate) return;

      this.$emit("action", this.selected);
    },
    clearSelected() {
      this.$refs.cptCodeRef.clearFields();
      this.$refs.modifierRef.clearFields();
    },
    resetFieldValidation() {
      this.$refs.cptCodeRef.$refs.autocompleteCptCode.resetValidation();
      this.$refs.modifierRef.$refs.autocompleteModifier.resetValidation();
    },
  },
  created() {
    if (this.dateOfService.length) {
      const timezoneOffset = this.getTimezoneOffset({
        date: this.dateOfService,
        minusSeconds: 1,
        zone: "America/Los_Angeles",
      });

      this.additionalFilter = {
        validityStartDate: {
          lt: this.$options.filters.getTimeDate(
            this.dateOfService,
            "YYYY-MM-DD HH:mm:ss",
            true,
            "America/Los_Angeles"
          ).utc,
          gt: "",
        },
        validityEndDate: {
          lt: "",
          gt: this.$options.filters.getTimeDate(
            this.$options.filters.getTimeDate(
              this.dateOfService,
              "YYYY-MM-DD",
              true,
              null
            ).utc,
            "YYYY-MM-DD HH:mm:ss",
            true,
            null,
            {
              days: 1,
              hours: timezoneOffset.hours,
              minutes: timezoneOffset.minutes,
              seconds: timezoneOffset.seconds,
            }
          ).utc,
        },
        archive: true,
        logicalOperator: "AND",
      };
    }
  },
};
