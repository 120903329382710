import PlaceOfServiceActions from "store/actions/place-of-service";
import ConversionTransaction from "store/actions/conversion-transaction";
import OrderActions from "store/actions/order";
import AddTestOrderModal from "../../modals/AddTestOrderModal";

export default {
  name: "TestOrderedSection",
  components: {
    AddTestOrderModal,
  },
  props: {
    orderSaved: {
      type: Boolean,
      required: true,
    },
    test: {
      type: Object,
      required: true,
    },
    notes: {
      type: Array,
      required: true,
    },
    insuranceCompanies: {
      type: Array,
      required: true,
    },
    billType: {
      type: String,
      required: true,
    },
    dateOfService: {
      type: String,
      required: true,
    },
    withPriceColumn: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      actions: new ConversionTransaction(),
      posActions: new PlaceOfServiceActions(),
      orderActions: new OrderActions(),
      showModal: false,
      showInfo: [],
      expandedRow: [],
      loading: {
        modal: false,
        table: false,
        content: true,
        validateAll: false,
        validating: [],
      },
      columns: [
        {
          text: "Test ID",
          align: "left",
          sortable: false,
          value: "testCode",
        },
        {
          text: "CPT Code",
          align: "left",
          sortable: false,
          value: "code",
        },
        {
          text: "Description",
          align: "left",
          sortable: false,
          value: "description",
        },
        {
          text: "Modifier",
          align: "left",
          sortable: false,
          value: "modifierCode",
        },
        {
          text: "Price",
          align: "left",
          sortable: false,
          value: "pricedAmount",
        },
        {
          text: "Unit",
          align: "left",
          sortable: false,
          value: "unit",
        },
        {
          text: "Place Of Service",
          align: "left",
          sortable: false,
          value: "posCode",
        },
        {
          text: "Lab Message",
          align: "left",
          sortable: false,
          value: "notes",
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
          value: "status",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
        },
      ],
      defaultPOS: {
        id: "522abf6f-5bd8-4ebe-bdae-2cf4453bf4f5",
        posCode: "81",
      },
      posOptions: [],
      testsOrdered: [],
      toEdit: {
        index: -1,
        cptCode: {},
        modifier: {},
      },
      paginationOptions: {
        page: 1,
        itemsPerPage: 10,
        sortOptions: {
          sortBy: "",
          sort: "",
          isDateColumn: false,
        },
      },
      perPageOptions: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "15",
          value: 15,
        },
        {
          text: "20",
          value: 20,
        },
      ],
      statusToValidate: ["Preview-Changed", "Draft", null],
      errorOccur: false,
    };
  },
  computed: {
    isValidateAllDisabled() {
      return this.testsOrdered
        .map(({ status }) => status?.label === "Preview-Changed")
        .every((bool) => !bool);
    },
    listColumns() {
      if (this.withPriceColumn) return this.columns;
      return this.columns.filter((i) => i.text !== "Price");
    },
  },
  methods: {
    expandRow(data) {
      const index = this.expandedRow.findIndex((row) => row.id == data.id);
      if (index !== -1) this.expandedRow.splice(index, 1);
      else this.expandedRow.push(data);
    },
    parseString(data, props) {
      return (data ? data[props]?.length > 13 : false)
        ? `${data[props].substring(0, 10)}...`
        : data
        ? data[props]
        : null;
    },
    randomStatus() {
      return Math.floor(Math.random() * this.status.length);
    },
    async validateAll() {
      try {
        this.loading.table = true;
        this.loading.validateAll = true;

        this.validateRequirements();
        const result = await this.actions.upsert({
          orderId: {
            value: this.$route.params.id,
            type: "UUID!",
            unincludeToFields: true,
          },
          insuranceCompanyId: {
            value: this.insuranceCompanies[0].payor.id,
            type: "UUID!",
            unincludeToFields: true,
          },
          orderBillType: {
            value: this.billType,
            type: "BillType!",
            unincludeToFields: true,
          },
        });
        if (result.success) {
          this.showNotifyMessage({
            message: "Validating all test ordered is successful.",
            type: "success",
          });
          await this.getTestsOrdered();
        } else
          this.showNotifyMessage({
            message: result.errors[0],
            type: "danger",
          });
      } catch (e) {
        this.errorOccur = true;
        this.showNotifyMessage({
          message: e?.errorMsg || "An error occur while validating test orders",
          type: "danger",
        });
      } finally {
        this.loading.table = false;
        this.loading.validateAll = false;
        this.loading.content = false;
      }
    },
    async updateTestOrderRow(data) {
      const testOrdered = this.testsOrdered[this.toEdit.index];
      try {
        this.loading.modal = true;
        const result = await this.updateConversion(
          Object.assign(JSON.parse(JSON.stringify(testOrdered)), data)
        );

        if (!result.success) {
          this.showNotifyMessage({
            message: result.errors[0],
            type: "danger",
          });
          this.loading.modal = false;
          return;
        }

        this.showModal = false;
        await this.getTestsOrdered();
        this.toEdit = {
          index: -1,
          cptCode: {},
          modifier: {},
        };
      } catch {
        this.errorOccur = true;
        this.showNotifyMessage({
          message: "An error occur while saving update",
          type: "danger",
        });
      } finally {
        this.loading.content = false;
        this.loading.modal = false;
      }
    },
    disablePaginationButton(button) {
      if (button == "first") return this.paginationOptions.page == 1;
      return (
        this.paginationOptions.page >=
        this.testsOrdered.length / this.paginationOptions.itemsPerPage
      );
    },
    totalPages(itemsPerPage) {
      const hasRemainder = this.testsOrdered.length % itemsPerPage != 0;
      return (
        Math.floor(this.testsOrdered.length / itemsPerPage) +
        (hasRemainder ? 1 : 0)
      );
    },
    async refreshData() {
      try {
        await this.getTestsOrdered();
      } catch (err) {
        this.errorOccur = true;
        this.showNotifyMessage({
          message: "An error occur while refreshing test ordered data.",
          type: "danger",
        });
      } finally {
        this.loading.content = false;
      }
    },
    async getPlaceOfServices() {
      try {
        if (!this.posOptions.length) {
          this.posOptions = [
            {
              posCode: "fetching...",
              disabled: true,
            },
          ];

          let result = await this.posActions.getPosList();

          if (result && result.length)
            this.posOptions = result.map((item) => {
              return {
                id: item.id,
                posCode: item.posCode,
              };
            });
        }
      } catch (err) {
        this.errorOccur = true;

        this.showNotifyMessage({
          message: `Unable to fetch data.`,
          type: "danger",
        });
      }
    },
    selectPOS(e, index) {
      if (e) this.__Test[index].placeOfService = e;
    },
    atCloseModal() {
      this.showModal = false;
      this.toEdit = {
        index: -1,
        cptCode: {},
        modifier: {},
      };
    },
    triggerEdit(id, index) {
      const testOrder = this.testsOrdered.find((test) => test.id == id);

      this.toEdit.index = index;
      this.toEdit.cptCode = testOrder.cptCode;
      this.toEdit.modifier = testOrder.modifier;
      this.$nextTick(() => {
        this.showModal = true;
      });
    },
    async onValidate(data) {
      let result;
      try {
        this.loading.table = true;
        data.validating = true;

        result = await this.updateConversion(data);

        if (!result.success) {
          this.showNotifyMessage({
            message: result.errors[0],
            type: "danger",
          });
          this.loading.table = false;
          data.validating = false;
          return;
        }

        this.showNotifyMessage({
          message: "Test order is validated successfuly.",
          type: "success",
        });
        await this.getTestsOrdered();
      } catch (e) {
        this.errorOccur = true;

        this.showNotifyMessage({
          message:
            e.errorMsg || "An Error occur while validating test ordered.",
          type: "danger",
        });
      } finally {
        this.loading.table = false;
        data.validating = false;
      }
    },
    triggerInfo(id) {
      id;
    },
    async triggerDelete(id) {
      try {
        this.loading.table = true;
        const result = await this.actions.revert(id);

        if (!result.success) {
          this.showNotifyMessage({
            message: result.errors[0],
            type: "danger",
          });
          return;
        }

        await this.getTestsOrdered();
        this.showNotifyMessage({
          message: "Test ordered is successfully deleted.",
          type: "success",
        });
      } catch (e) {
        this.errorOccur = true;

        this.showNotifyMessage({
          message: "An error occur while deleting test ordered.",
          type: "danger",
        });
      } finally {
        this.loading.table = false;
      }
    },
    populateTestsOrder(tests) {
      let toExpand = [];
      this.testsOrdered = tests?.map((data) => {
        if (data.conversionTransaction?.cptCodeConversions) toExpand.push(data);
        return {
          id: data.id,
          test: {
            id: data.test?.id || null,
            testCode: data.test.testCode,
            label: data.test.label,
            note: data.test?.notes?.length
              ? data.test.notes.map((note) => note.note).join(",\r\n")
              : "-",
          },
          cptCode: data.cptCode || {},
          modifier: data.modifier || {},
          status: data.status || { label: "Draft" },
          unit: data.unit,
          placeOfService: data.placeOfService || this.defaultPOS,
          conversionTransaction: data.conversionTransaction,
          cptCodeConversions:
            data.conversionTransaction?.cptCodeConversions || [],
          pricedAmount: data.pricedAmount || 0,
          isConverted: data.status?.label === "Converted",
          validating: false,
          errorInfo: data.errorInfo
        };
      });
      this.loading = {
        modal: false,
        table: false,
        content: false,
        validating: false,
      };
      this.$nextTick(() => {
        this.expandedRow = toExpand;
      });
    },
    async getTestsOrdered() {
      this.loading.table = false;
      this.loading.validateAll = false;
      this.loading.content = true;
      this.errorOccur = false;
      this.testsOrdered = [];
      const { testsOrdered } = await this.orderActions.get({
        variables: {
          type: "UUID!",
          id: this.$route.params.id,
        },
        limitData: [
          this.buildSubQuery("testsOrdered", [
            "pricedAmount",
            this.buildSubQuery("errorInfo", [
              "code",
              "message",
            ], false),
            this.buildSubQuery("cptCode", [
              "code",
              "description",
            ]),
            this.buildSubQuery("test", ["label", "testCode"]),
            this.buildSubQuery("modifier", ["modifierCode"]),
            this.buildSubQuery("status", ["label"]),
            this.buildSubQuery("placeOfService", ["posCode"]),
            this.buildSubQuery(
              "conversionTransaction",
              [
                "groupId",
                this.buildSubQuery("insuranceCompany", ["name"]),
                this.buildSubQuery("converter", ["name", "internalId"]),
                this.buildSubQuery("cptCodeConversions", [
                  "pricedAmount",
                  "unit",
                  this.buildSubQuery("cptCode", ["code"]),
                  this.buildSubQuery("modifier", ["modifierCode"]),
                ]),
              ],
              false
            ),
          ]),
        ],
      });
      this.populateTestsOrder(testsOrdered);
    },
    validateRequirements() {
      if (!this.billType || !this.insuranceCompanies.length)
        throw { errorMsg: "Bill Type and Primary Payor is required." };
    },
    async saveUpdateData(data) {
      let result;
      let testOrdered = this.testsOrdered[this.toEdit.index];
      let isUpdate = this.toEdit.index != -1;
      this.loading.modal = true;
      try {
        this.validateRequirements();
        if (isUpdate)
          result = await this.updateConversion(
            Object.assign(JSON.parse(JSON.stringify(testOrdered)), data)
          );
        else result = await this.createConversion(data);

        if (!result.success) {
          this.showNotifyMessage({
            message: result.errors[0],
            type: "danger",
          });
        } else {
          this.showModal = false;
          this.loading.content = true;
          this.showNotifyMessage({
            message: `${isUpdate ? "Update" : "Add"} test order successfuly`,
            type: "success",
          });
          this.toEdit = {
            cptCode: {},
            modifier: {},
            index: -1,
          };
          await this.getTestsOrdered();
        }
      } catch (e) {
        this.errorOccur = true;

        this.showNotifyMessage({
          message:
            e.errorMsg ||
            `An error occur while ${
              isUpdate ? "updating" : "adding"
            } test order.`,
          type: "danger",
        });
      } finally {
        this.loading.modal = false;
        this.loading.content = false;
      }
    },
    async createConversion(data) {
      let params = {
        orderId: {
          type: "UUID!",
          value: this.$route.params.id,
          unincludeToFields: true,
        },
        testId: {
          type: "UUID!",
          value: this.test.id,
          unincludeToFields: true,
        },
        cptCodeId: {
          type: "UUID",
          value: data.cptCode.id,
          unincludeToFields: true,
        },
        insuranceCompanyId: {
          type: "UUID!",
          value: this.insuranceCompanies[0].payor.id,
          unincludeToFields: true,
        },
        orderBillType: {
          type: "BillType!",
          value: this.billType,
          unincludeToFields: true,
        },
      };

      if (data.modifier.id)
        params["modifierId"] = {
          type: "UUID",
          value: data.modifier.id,
          unincludeToFields: true,
        };

      return await this.actions.create(params);
    },
    async updateConversion(data) {
      let params = {
        orderId: {
          type: "UUID!",
          value: this.$route.params.id,
          unincludeToFields: true,
        },
        groupId: {
          type: "UUID!",
          value: data.conversionTransaction.groupId,
          unincludeToFields: true,
        },
        testId: {
          type: "UUID!",
          value: data.test.id,
          unincludeToFields: true,
        },
        cptCodeId: {
          type: "UUID",
          value: data.cptCode.id,
          unincludeToFields: true,
        },
        insuranceCompanyId: {
          type: "UUID!",
          value: this.insuranceCompanies[0].payor.id,
          unincludeToFields: true,
        },
        orderBillType: {
          type: "BillType!",
          value: this.billType,
          unincludeToFields: true,
        },
        modifierId: {
          type: "UUID",
          value: data.modifier.id,
          unincludeToFields: true,
        },
      };

      if (data.modifier.id)
        params["modifierId"] = {
          type: "UUID",
          value: data.modifier.id,
          unincludeToFields: true,
        };

      return await this.actions.update(params);
    },
  },
  async mounted() {
    await this.getPlaceOfServices();
  },
  created() {
    this.$nextTick(async () => {
      try {
        await this.getTestsOrdered();
      } catch (e) {
        this.errorOccur = true;

        this.loading.content = false;
      } finally {
        this.loading.content = false;
      }
    });
  },
};
